<template>
  <section>

    <div class="columns is-marginless is-multiline">
      <div class="column is-12">
          <div class="columns is-gapless is-marginless is-centered is-mobile">
            <div class="column is-narrow is-mobile">
              <div class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light wa-underlined" >RouteLogger</div>
            </div>
          </div>
      </div>
      <div class="column is-12">
        <b-button class="myMenuButton" :type="buttonType('general5')" @click="setMode('general5')">General 5</b-button>
        <b-button class="myMenuButton" :type="buttonType('general15')" @click="setMode('general15')">General 15</b-button>
        <b-button class="myMenuButton" :type="buttonType('general60')" @click="setMode('general60')">General 60</b-button>
        <b-button class="myMenuButton" :type="buttonType('general24h')" @click="setMode('general24h')">General 24H</b-button>
      </div>
      <div class="column is-12">
        <b-button class="myMenuButton" :type="buttonType('users5')" @click="setMode('users5')">Users 5</b-button>
        <b-button class="myMenuButton" :type="buttonType('users15')" @click="setMode('users15')">Users 15</b-button>
        <b-button class="myMenuButton" :type="buttonType('users60')" @click="setMode('users60')">Users 60</b-button>
        <b-button class="myMenuButton" :type="buttonType('users24h')" @click="setMode('users24h')">Users 24H</b-button>
      </div>
      <div class="column is-12">
        <b-button class="myMenuButton" :type="buttonType('routes5')" @click="setMode('routes5')">Routes 5</b-button>
        <b-button class="myMenuButton" :type="buttonType('routes15')" @click="setMode('routes15')">Routes 15</b-button>
        <b-button class="myMenuButton" :type="buttonType('routes60')" @click="setMode('routes60')">Routes 60</b-button>
        <b-button class="myMenuButton" :type="buttonType('routes24h')" @click="setMode('routes24h')">Routes 24H</b-button>
      </div>
      <div class="column is-12">
        <b-button class="myMenuButton" :type="buttonType('ip5')" @click="setMode('ip5')">ip 5</b-button>
        <b-button class="myMenuButton" :type="buttonType('ip15')" @click="setMode('ip15')">ip 15</b-button>
        <b-button class="myMenuButton" :type="buttonType('ip60')" @click="setMode('ip60')">ip 60</b-button>
        <b-button class="myMenuButton" :type="buttonType('ip24h')" @click="setMode('ip24h')">ip 24H</b-button>
      </div>
    </div>


    <div v-if="data && mode === 'general'" class="columns is-mobile is-gapless is-centered is-multiline m2rem">
      <h1 class="column is-12 centerText">Server Load</h1>
      <b-table class="column is-6"
          :data="data"
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :loading="isLoading"
          :mobile-cards="true">

          <b-table-column field="id" label="id" width="40" v-slot="props" centered numeric sortable>
              {{ props.row.id }}
          </b-table-column>
          <b-table-column field="sortpos" label="sortid" width="40" v-slot="props" centered numeric sortable>
              {{ props.row.sortpos }}
          </b-table-column>

          <b-table-column field="name" label="name" v-slot="props" sortable>
              {{ props.row.name }}
          </b-table-column>

          <b-table-column field="ip" label="ip" v-slot="props" centered sortable>
              {{ props.row.ip ? props.row.ip : '-' }}
          </b-table-column>

          <b-table-column field="totalLoad" label="total load" v-slot="props" centered sortable numeric >
            {{ props.row.totalLoad }}
          </b-table-column>

          <b-table-column field="totalreq" label="total req" v-slot="props" centered sortable numeric >
            {{ props.row.totalreq }}
          </b-table-column>

          <b-table-column field="lastReq" label="last Req"  centered v-slot="props" sortable>
              {{ props.row.lastReq ? DDMMYYYY_HHii(new Date(props.row.lastReq)) : '-' }}
          </b-table-column>

          <template #empty>
              <div class="has-text-centered">No records</div>
          </template>

      </b-table>
    </div>


    <div v-if="users && mode === 'users'" class="columns is-mobile is-gapless is-centered is-multiline m2rem">
      <h1 class="column is-12 centerText">Users Load</h1>
      <b-table class="column is-8"
          :data="users"
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :loading="isLoading"

          :paginated="true"
          :isPaginationSimple="false"
          perPage="50"

          :mobile-cards="true">

          <b-table-column field="userId" label="user Id" width="60" v-slot="props" centered numeric sortable searchable>
              {{ props.row.userId }}
          </b-table-column>
          <b-table-column field="email" label="email" width="200" v-slot="props" centered sortable searchable>
              {{ props.row.email }}
          </b-table-column>
          <b-table-column field="roles" label="roles" width="200" v-slot="props" centered sortable searchable>
              {{ props.row.roles }}
          </b-table-column>

          <b-table-column field="last_request" label="last Req" v-slot="props" sortable>
            {{ props.row.last_request ? DDMMYYYY_HHii(new Date(props.row.last_request)) : '-' }}
          </b-table-column>

          <b-table-column field="total_Load" label="total Load" v-slot="props" centered sortable numeric >
              {{ props.row.total_Load }}
          </b-table-column>

          <b-table-column field="total_request" label="total Req" v-slot="props" centered sortable numeric >
            {{ props.row.total_request }}
          </b-table-column>

          <template #empty>
              <div class="has-text-centered">No records</div>
          </template>

      </b-table>
    </div>


    <div v-if="routes && mode === 'routes'" class="columns is-mobile is-gapless is-centered is-multiline m2rem">
      <h1 class="column is-12 centerText">Route Load</h1>
      <b-table class="column is-12"
          :data="routes"
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :loading="isLoading"

          :paginated="true"
          :isPaginationSimple="false"
          perPage="100"

          :mobile-cards="true">

          <b-table-column field="url_ref" label="url_ref" width="600" v-slot="props" sortable searchable>
              {{ props.row.url_ref }}
          </b-table-column>

          <b-table-column field="lastReq" label="lastReq" v-slot="props" centered sortable>
              {{ props.row.lastReq ? DDMMYYYY_HHii(new Date(props.row.lastReq)) : '-' }}
          </b-table-column>
          
          <b-table-column field="average" label="average" v-slot="props" sortable centered numeric >
            {{ props.row.average }}
          </b-table-column>

          <b-table-column field="totalLoad" label="total Load" v-slot="props" centered sortable numeric >
            {{ props.row.totalLoad }}
          </b-table-column>

          <b-table-column field="totalreq" label="total Req" v-slot="props" centered sortable numeric >
            {{ props.row.totalreq }}
          </b-table-column>

          <template #empty>
              <div class="has-text-centered">No records</div>
          </template>

      </b-table>
    </div>


    <div v-if="ips && mode === 'ip'" class="columns is-mobile is-gapless is-centered is-multiline m2rem">
      <h1 class="column is-12 centerText">IP Load</h1>
      <b-table class="column is-12"
          :data="ips"
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :loading="isLoading"

          :paginated="true"
          :isPaginationSimple="false"
          perPage="100"

          :mobile-cards="true">

          <b-table-column field="ip" label="ip" width="600" v-slot="props" sortable searchable>
              {{ props.row.ip }}
          </b-table-column>

          <b-table-column field="lastReq" label="lastReq" v-slot="props" centered sortable>
              {{ props.row.last_request ? DDMMYYYY_HHii(new Date(props.row.last_request)) : '-' }}
          </b-table-column>
          
          <b-table-column field="total_Load" label="total Load" v-slot="props" centered sortable numeric >
            {{ props.row.total_Load }}
          </b-table-column>

          <b-table-column field="total_request" label="total Req" v-slot="props" centered sortable numeric >
            {{ props.row.total_request }}
          </b-table-column>

          <template #empty>
              <div class="has-text-centered">No records</div>
          </template>

      </b-table>
    </div>

  </section>
</template>

<script>
import { DDMMYYYY_HHii } from '@/helpers/dates.js';
import axios_api from '@/plugins/axios_api';
import { API_ROOT, WEB_ROOT } from '@/config/app.js';

export default {
  name: "AllUserOverview",
  components: {},
  data() {
    return {
      WEB_ROOT            : WEB_ROOT,
      API_ROOT            : API_ROOT,
      SITE_ROOT           : process.env.VUE_APP_SITE_MAIN,
      isLoading: false,
      mode: 'general',
      button: '',
      data: null,
      users: null,      
      routes: null,      
      ips: null,      

    }
  },
  async mounted(){
    this.setMode('general5')
  },
  methods: {
    setMode(newMode){
      if (newMode === 'general5') {
        this.loadGeneralData(5*60*1000)
        this.mode = 'general'
        this.button = 'general5'
      } else if (newMode === 'general15') {
        this.loadGeneralData(15*60*1000)
        this.mode = 'general'
        this.button = 'general15'
      } else if (newMode === 'general60') {
        this.loadGeneralData(60*60*1000)
        this.mode = 'general'
        this.button = 'general60'
      } else if (newMode === 'general24h') {
        this.loadGeneralData(24*60*60*1000)
        this.mode = 'general'
        this.button = 'general24h'
      }

      if (newMode === 'users5') {
        this.loadUsersData(5*60*1000)
        this.mode = 'users'
        this.button = 'users5'
      } else if (newMode === 'users15') {
        this.loadUsersData(15*60*1000)
        this.mode = 'users'
        this.button = 'users15'
      } else if (newMode === 'users60') {
        this.loadUsersData(60*60*1000)
        this.mode = 'users'
        this.button = 'users60'
      } else if (newMode === 'users24h') {
        this.loadUsersData(24*60*60*1000)
        this.mode = 'users'
        this.button = 'users24h'
      }

      if (newMode === 'routes5') {
        this.loadRouteData(5*60*1000)
        this.mode = 'routes'
        this.button = 'routes5'
      } else if (newMode === 'routes15') {
        this.loadRouteData(15*60*1000)
        this.mode = 'routes'
        this.button = 'routes15'
      } else if (newMode === 'routes60') {
        this.loadRouteData(60*60*1000)
        this.mode = 'routes'
        this.button = 'routes60'
      } else if (newMode === 'routes24h') {
        this.loadRouteData(24*60*60*1000)
        this.mode = 'routes'
        this.button = 'routes24h'
      }

      if (newMode === 'ip5') {
        this.loadIPData(5*60*1000)
        this.mode = 'ip'
        this.button = 'ip5'
      } else if (newMode === 'ip15') {
        this.loadIPData(15*60*1000)
        this.mode = 'ip'
        this.button = 'ip15'
      } else if (newMode === 'ip60') {
        this.loadIPData(60*60*1000)
        this.mode = 'ip'
        this.button = 'ip60'
      } else if (newMode === 'ip24h') {
        this.loadIPData(24*60*60*1000)
        this.mode = 'ip'
        this.button = 'ip24h'
      }
    },
    async loadGeneralData(maxTime = 5*60*1000){
      this.data = null
      this.setLoading()
      try {
        let response = await axios_api.get(`${API_ROOT}/routeloggerdata/servers/${maxTime}`);
        this.data = response.data.payload.general
      } catch (err) {
        this.data = null
      }
      this.setFinished()
    },
    async loadUsersData(maxTime = 5*60*1000){
      this.users = null
      this.setLoading()
      try {
        let response = await axios_api.get(`${API_ROOT}/routeloggerdata/users/${maxTime}`);
        let users = response.data.payload.users
        for(let user of users) {
          user.total_Load = parseInt(user.total_Load)
        }
        this.users = users
        // console.info(`this.users:`, this.users)
      } catch (err) {
        this.users = null
      }
      this.setFinished()
    },
    async loadRouteData(maxTime = 5*60*1000){
      this.users = null
      this.setLoading()
      try {
        let response = await axios_api.get(`${API_ROOT}/routeloggerdata/routes/${maxTime}`);
        let routes = response.data.payload.routes
        for(let route of routes) {
          route.totalLoad = parseInt(route.totalLoad)
          route.average = parseFloat(route.average)
        }
        this.routes = routes
        // console.info(`this.routes:`, this.routes)
      } catch (err) {
        this.users = null
      }
      this.setFinished()
    },
    async loadIPData(maxTime = 5*60*1000){
      this.users = null
      this.setLoading()
      try {
        let response = await axios_api.get(`${API_ROOT}/routeloggerdata/ips/${maxTime}`);
        let ips = response.data.payload.ips
        for(let route of ips) {
          route.total_Load = parseInt(route.total_Load)
          route.total_request = parseInt(route.total_request)
        }
        this.ips = ips
        // console.info(`this.routes:`, this.routes)
      } catch (err) {
        this.users = null
      }
      this.setFinished()
    },
    buttonType(buttonTag){
      if (this.button === buttonTag) return 'is-primary'
      return 'is-info'
    },
    setLoading(){
      this.isLoading = true
    },
    setFinished(){
      this.isLoading = false
    },
    DDMMYYYY_HHii,
    dateThAttrs() { },
    columnTdAttrs() {}
}
}
</script>

<style>
  .myMenuButton{
    margin: 5px
  }
  .centerText{
    text-align: center;
    margin: 15px
  }
</style>

